function ready(fn) {
  if (document.readyState !== 'loading') {
    fn();
    return;
  }
  document.addEventListener('DOMContentLoaded', fn);
}

function initCookieBanner() {
  const cookiesBanner = document.querySelector('.cookies-banner');
  const acceptButton = cookiesBanner.querySelector('.buttonscookie');
  // const rejectButton = cookiesBanner.querySelector('.close');

  acceptButton.addEventListener('click', () => {
    localStorage.setItem('cookiesAccepted', 'true');
    cookiesBanner.style.display = 'none';
  });

  // rejectButton.addEventListener('click', () => {
  //   localStorage.setItem('cookiesRejected', 'true');
  //   cookiesBanner.style.display = 'none';
  // });

  if (localStorage.getItem('cookiesAccepted') === 'true' || localStorage.getItem('cookiesRejected') === 'true') {
    cookiesBanner.style.display = 'none';
  } else {
    cookiesBanner.style.display = 'flex';
  }
}

ready(function () {
  initCookieBanner();
});


ready(function () {


  const showMenu = (toggleId, navId) => {
    const toggle = document.getElementById(toggleId),
      nav = document.getElementById(navId)

    toggle.addEventListener('click', () => {
      // Add show-menu class to nav menu
      nav.classList.toggle('show-menu')



      // Add show-icon to show and hide the menu icon
      toggle.classList.toggle('show-icon')

    })


  };

  showMenu('nav-toggle', 'nav-menu');

});


$(function () {

  var url = window.location.pathname,
    urlRegExp = new RegExp(url.replace(/\/$/, '') + "$"); // create regexp to match current url pathname and remove trailing slash if present as it could collide with the link in navigation in case trailing slash wasn't present there
  // now grab every link from the navigation
  $('.menu a').each(function () {
    // and test its normalized href against the url pathname regexp
    if (urlRegExp.test(this.href.replace(/\/$/, ''))) {
      $(this).addClass('activenavlink');
    }
  });

});

ready(function () {

  var menuItems = document.querySelectorAll('#nav-menu ul li a');
  menuItems.forEach(function (item) {

    // zamykanie menu po kliknieciu
    item.addEventListener('click', function () {
      // setTimeout(function () {
      document.getElementById('nav-menu').classList.remove('show-menu');
      // }, 500);
      document.getElementById('nav-toggle').classList.remove('show-icon');

    });
  });

});



$(document).ready(function () {
  var btn = $('.top');

  $(window).scroll(function () {
    if ($(window).scrollTop() > 400) {
      btn.addClass('show');
    } else {
      btn.removeClass('show');
    }
  });

  btn.on('click', function (e) {
    e.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, '400');
  });
});

